/*
EXTREMELY IMPORTANT -> Do not touch the max-width values (600px) otherwise it would break (in a small way though) the layout on UK's embedded iframe -> it would switch to mobile even on desktop
*/

/* .bg {
width: 100%;
height: 100%;
} */

.App {
  text-align: center;
  z-index: 9999;
  position: relative;
}

.App.fullscreen {
  height: 100vh;
  background: transparent; /* Important so that the background doesn't fill the screen at once */
}

.chat-button {
  position: fixed;
  bottom: 20px;
  right: 8px;
  width: 34px;
  height: 34px;
  background-color: var(--theme-color);
  border: none;
  border-radius: 6px;
  box-shadow:
    0px 6px 16px -1px rgba(0, 0, 0, 0.15),
    0px 4px 8px 0px rgba(0, 0, 0, 0.2);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: transform 0.3s ease;
  color: white;
}

@media (max-width: 600px) {
  .chat-button {
    font-size: 18px;
    bottom: 16px;
    right: 24px;
  }
}

.chat-button:hover {
  transform: scale(1.1);
}

.chat-button.pressed {
  transform: scale(0.85);
}

.chat-icon {
  width: 18px;
  height: 18px;
}

.chat-window {
  position: fixed;
  bottom: 64px;
  /* 52px (button height) + 16px (spacing) + 20px (bottom margin) */
  right: 8px;
  width: 450px;
  height: 650px;
  background-color: white;
  border: 2px solid #f5f5f5;
  border-radius: 8px;
  box-shadow:
    0px 4px 64px -12px rgba(0, 0, 0, 0.08),
    0px 4px 64px -12px rgba(0, 0, 0, 0.08),
    0px 8px 16px 0px rgba(0, 0, 0, 0.13);
  display: flex;
  flex-direction: column;
  transform: scale(0);
  /* Initial scale for animation */
  transition:
    transform 0.45s cubic-bezier(0.23, 1, 0.32, 1),
    opacity 0.4s cubic-bezier(0.23, 1, 0.32, 1);
  transform-origin: bottom right;
  opacity: 0;
  /* Animation for the fullscreen transition */
  transition: all 0.45s cubic-bezier(0.23, 1, 0.32, 1);
}

.chat-window-fullscreen {
  position: fixed;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  bottom: 0;
  right: 0;
  transition: all 0.45s cubic-bezier(0.23, 1, 0.32, 1);
  /* transition: height 0.45s cubic-bezier(.23, 1, .32, 1); */
  transform-origin: bottom right;
  background-color: white;
}

@media (max-width: 600px) {
  .chat-window {
    width: calc(100% - 48px); /* Full width with padding on sides */
    height: 80%; /* 80% height of the phone */
    right: 24px;
    left: 24px;
  }
}

.chat-window.open {
  opacity: 1;
  transform: scale(1);
}

.chat-window.closed {
  opacity: 0;
  transform: scale(0);
}

.chat-header-container {
  background-color: white;
  padding: 24px 24px 20px 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 2px solid #f5f5f5;
}

.chat-header {
  display: flex;
  align-items: center;
}

.chat-warning {
  text-transform: uppercase;
  font-family: Roboto;
  font-size: 14px;
  font-weight: 600;
  opacity: 0.4;
  padding: 20px 12px;
}

.header-buttons {
  display: flex;
  align-items: center;
  gap: 16px;
}

.header-button {
  width: 24px;
  height: 24px;
  background: transparent;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0.5;
}

.header-button:hover {
  opacity: 1;
}

.header-image {
  width: 32px;
  height: 32px;
  margin-right: 10px;
}

.header-text {
  color: #000;
  font-family: Roboto;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.chat-footer {
  width: 100%;
  /* padding: 24px; */
  display: flex;
  align-items: center;
  border-top: 2px solid #f5f5f5;
}

.chat-input {
  flex: 1;
  padding: 16px 24px;
  font-family: "Roboto", sans-serif;
  font-size: 17px;
  line-height: 24px;
  background-color: white;
  border: none;
  border-radius: 0;
  outline: none;
  resize: none; /* Prevent manual resizing */
  box-sizing: border-box;
  overflow: auto; /* Takes care of hiding and showing the scrollbase */
  max-height: calc(
    6 * 24px + 2 * 16px
  ); /* 6 lines * lineheight + top and bottom (2) * padding */
  caret-color: var(--theme-color);
}

.send-button {
  width: 32px;
  height: 32px;
  background: transparent;
  border: none;
  margin-left: 14px;
  margin-right: 10px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.send-button:disabled {
  cursor: not-allowed;
  opacity: 0.5; /* Dim the button when disabled */
}

.send-icon {
  width: 24px;
  height: 24px;
  fill: var(--theme-color);
}

.chat-history {
  flex-grow: 1; /* Occupy remaining space */
  flex-shrink: 1;
  overflow-y: auto; /* Enable vertical scrolling */
  padding: 24px; /* Padding for the chat history */
  padding-top: 0px;
  display: flex;
  flex-direction: column;
  overflow-anchor: none;
}

.message {
  max-width: 70%;
  padding: 10px 14px;
  border-radius: 6px;
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  line-height: 23px;
  text-align: left;
}

.user-message {
  align-self: flex-end; /* Align to the right */
  background-color: var(--theme-color);
  color: white;
}

.user-message-container {
  display: flex;
  justify-content: flex-end; /* Align icon and message at the bottom */
  margin-top: 16px;
}

.ai-message-container {
  display: flex;
  align-items: flex-start; /* Align icon and message at the top */
  margin-top: 16px;
}

.ai-icon {
  width: 32px;
  height: 32px;
  margin-right: 12px; /* Space between the icon and the message */
  margin-top: 6px; /* The icon is aligned to the top and offset so it appears centerd for one-line messages */
}

.ai-message {
  background-color: #f0f3f5;
  color: black;
  margin-bottom: 4px;
}

.ai-message p {
  margin: 2px; /* Adjust the padding given by the reactmarkdown class */
}

.feedback-buttons {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  padding-right: 8px;
}

.feedback-button {
  background: none;
  border: none;
  cursor: pointer;
  font-size: 14px;
  margin: 2px 0;
  opacity: 0.6;
  transition:
    opacity 0.2s,
    transform 0.2s;
  padding: 2px;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.feedback-button:hover {
  opacity: 1;
  transform: scale(1.1);
}

.feedback-button.active {
  opacity: 1;
  color: var(--theme-color); /* Use theme color for active state */
}

.feedback-button:disabled {
  cursor: default;
  opacity: 0.3;
}
