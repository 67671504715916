.source-block {
  padding: 12px 16px;
  font-size: 16px;
  margin-left: 44px; /* Offset to align with messages */
  line-height: 24px;
  background-color: #f0f3f5;
  color: black;
  border-radius: 6px;
  margin-top: 4px; /* Instead of gap in the App.css, we add this margin-top */
  max-width: 69%; /* should be 70% as the message but for some reason it is slightly off, this fix good enough for now */
  width: fit-content;
}

.source-header {
  font-size: 14px;
  text-transform: uppercase;
  font-weight: 600;
  display: flex;
  align-items: center;
  opacity: 0.6;
  margin-bottom: 4px;
}

.source-icon {
  margin-right: 8px;
  width: 20px;
  height: 20px;
  color: red;
}

.source-list {
  list-style-type: decimal;
  padding-left: 18px;
  margin: 0px;
  text-align: left;
}

.source-list li {
  margin-bottom: 4px;
}

.source-list a {
  color: #1962f5;
  text-decoration: underline;
  overflow-wrap: break-word;
  word-break: break-word;
}

.source-list li::marker {
  font-size: 14px;
  color: #626060;
  font-weight: 600;
}
